export const trackInitiateCheckout = () => {
    const fbq = (window as any).fbq || undefined;
    if (typeof fbq === "function") {
        fbq("track", "InitiateCheckout");
    }
};

export const trackPurchase = (purchaseValue) => {
    const fbq = (window as any).fbq || undefined;
    if (typeof fbq === "function") {
        fbq("track", "Purchase", {
            currency: "VND",
            value: purchaseValue,
        });
    };
}
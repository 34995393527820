import React, { useLayoutEffect } from "react"
import Img from "gatsby-image"
import classes from "./lightbox.module.scss"
import { useLockBodyScroll } from "../../utils/useLockBodyScroll"

export const Lightbox = ({ fluid, close }) => {
  useLockBodyScroll()
  return (
    <div className={classes.root} onClick={close}>
      <div className={classes.header}>
        <button onClick={close} className={classes.closeBtn}>
          <img src={require("../../images/x.png")} />
        </button>
      </div>
      <div className={classes.body}>
        <Img
          fluid={fluid}
          imgStyle={{ maxHeight: "calc(100vh - 140px)", objectFit: "contain" }}
        ></Img>
      </div>
    </div>
  )
}

import React, { useEffect, useMemo, useState } from "react"
import Modal from "../Modal"
import classes from "./purchaseModal.module.scss"
import axios from "axios"
import classNames from "classnames"
import { useForm } from "react-hook-form"
import Img from "gatsby-image"
import { ErrorMessage } from "@hookform/error-message"
import md5 from "md5"
import { Spinner } from "../Spinners/spinner"
import { sendCheckoutTrigger, sendPurchaseTrigger } from "../../utils/gtmEvents"
import { trackInitiateCheckout, trackPurchase } from "../../utils/fqbEvents"
const API_URL = "https://landing-pages-api.dtx.asia/order"
const PurchaseModal = ({ product, visible, onClose, images }) => {
  const [state, setState] = useState<"default" | "success" | "failure">(
    "default"
  )
  useEffect(() => {
    if (!visible) {
      setState("default")
    } else {
      // if user trigger the modal then we should send 1 checkout event
      sendCheckoutTrigger([
        {
          name: product.name,
          price: 3099000,
          brand: "Curnon",
          quantity: 1,
        },
      ])
      trackInitiateCheckout()
    }
  }, [visible])
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [serverErrors, setServerErrors] = useState<{
    name?: string
    phone?: string
    address?: string
    email?: string
    [key: string]: string
  }>({ name: "" })
  const { register, handleSubmit, watch, errors } = useForm()

  const onSubmit = data => {
    setIsSubmitting(true)
    axios
      .post(API_URL, {
        name: data.name,
        phone: data.phone,
        address: data.address,
        email: data.email,
        product: product.name,
        source: "jackson-landing-page",
        url: window ? window.location.href : "undefined",
        table: "Jackson",
        code: data.code,
      })
      .then(resp => {
        setIsSubmitting(false)
        if (resp.data.some(el => el.code == 0)) {
          setState("success")
          // add purchase event
          sendPurchaseTrigger([
            {
              name: product.name,
              price: 3099000,
              coupon: data.code,
              brand: "Curnon",
              quantity: 1,
            },
          ])
          trackPurchase(3099000)
        }
      })
      .catch(err => {
        setIsSubmitting(false)
        setServerErrors(err?.response?.data?.errors || {})
      })
  }
  const watchedCouponCode = watch("code", "")
  const isValidCouponCode = useMemo(() => {
    return watchedCouponCode.toLowerCase() == "js150"
  }, [watchedCouponCode])
  return (
    <Modal>
      <div
        className={classNames(classes.backdrop, {
          [classes.visible]: visible,
        })}
        onClick={() => {
          onClose()
        }}
      ></div>
      <div
        className={classNames(classes.root, {
          [classes.visible]: visible,
        })}
      >
        <p className={classes.title}>ĐƠN HÀNG JACKSON CỦA BẠN</p>
        {state === "success" && (
          <div className={classes.success}>
            <div className={classes.successIcon}>
              <img src={require("../../images/check.png")} />
            </div>
            <p>Chúc mừng bạn đã đặt hàng thành công</p>
          </div>
        )}
        {state === "failure" && (
          <div className={classes.success}>
            <div className={classes.successIcon}>
              <img src={require("../../images/check.png")} />
            </div>
            <p>Có lỗi xảy ra khi đặt hàng</p>
          </div>
        )}
        <div className={classes.product}>
          <Img fluid={images.preview} className={classes.image} />
          <div>
            <p className={classes.name}>{product.name}</p>
            <p
              className={classes.price}
              style={{ color: isValidCouponCode ? "#42b255" : undefined }}
            >
              {isValidCouponCode ? `2,949,000vnđ` : product.price}
            </p>
          </div>
        </div>

        <div className={classes.services}>
          <div className={classes.service}>
            <img src={require("../../images/truck.png")} />
            <p>{"FREESHIP\nTOÀN QUỐC"}</p>
          </div>
          <div className={classes.service}>
            <img src={require("../../images/shield.png")} />
            <p>{"1 ĐỔI 1 TRONG VÒNG 3 NGÀY,\nBẢO HÀNH 10 NĂM"}</p>
          </div>
        </div>

        <button onClick={onClose} className={classes.closeBtn}>
          <img src={require("../../images/x.png")} />
        </button>
        {state === "default" && (
          <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
            <div className={classes.inputWrapper}>
              <label htmlFor="fullname">Họ và tên</label>
              <input
                id="name"
                className={classes.input}
                name="name"
                ref={register({ required: true, maxLength: 50, minLength: 2 })}
                disabled={isSubmitting}
              />
              {serverErrors.name != "" && (
                <span className={classes.inputError}>{serverErrors.name}</span>
              )}
            </div>

            <div className={classes.inputWrapper}>
              <label htmlFor="phone">Số điện thoại</label>
              <input
                id="phone"
                className={classes.input}
                name="phone"
                ref={register({ required: true, maxLength: 50, minLength: 2 })}
                disabled={isSubmitting}
              />
              {serverErrors.phone != "" && (
                <span className={classes.inputError}>{serverErrors.phone}</span>
              )}
            </div>

            <div className={classes.inputWrapper}>
              <label htmlFor="email">Email</label>
              <input
                id="email"
                className={classes.input}
                name="email"
                ref={register({ required: true, maxLength: 50, minLength: 2 })}
                type="email"
                disabled={isSubmitting}
              />
              {serverErrors.email != "" && (
                <span className={classes.inputError}>{serverErrors.email}</span>
              )}
            </div>

            <div className={classes.inputWrapper}>
              <label htmlFor="address">Địa chỉ nhận hàng</label>
              <input
                id="address"
                className={classes.input}
                name="address"
                ref={register({ required: true, maxLength: 50, minLength: 2 })}
                disabled={isSubmitting}
              />
              {serverErrors.address != "" && (
                <span className={classes.inputError}>
                  {serverErrors.address}
                </span>
              )}
            </div>
            <div className={classes.inputWrapperCenter}>
              <label htmlFor="code">Mã giảm giá</label>
              <input
                id="code"
                className={classes.input}
                name="code"
                ref={register({ required: false, maxLength: 50, minLength: 2 })}
                disabled={isSubmitting}
              />
              {serverErrors.code != "" && (
                <span className={classes.inputError}>{serverErrors.code}</span>
              )}
              {isValidCouponCode && (
                <div className={classes.inputSuccess}>
                  - Giá sản phẩm đã được điều chỉnh giảm <strong>150k</strong>.
                  <br />- Bấm nút <strong>Đặt Hàng Ngay</strong> để hoàn tất đơn
                  hàng của bạn.
                </div>
              )}
              {watchedCouponCode != "" && !isValidCouponCode && (
                <span className={classes.inputError}>
                  - Mã giảm giá không hợp lệ.
                </span>
              )}
            </div>

            <button className={classes.buyBtn} disabled={isSubmitting}>
              {isSubmitting ? <Spinner></Spinner> : <span>ĐẶT HÀNG NGAY</span>}
            </button>
          </form>
        )}
        {state === "success" && (
          <button className={classes.continueBtn} onClick={onClose}>
            TIẾP TỤC MUA SẮM
          </button>
        )}
        <div className={classes.note}>
          <p>
            - Lưu ý: Curnon sẽ liên lạc lại với bạn trong 24h để xác nhận đơn
            hàng và phương thức thanh toán.
          </p>
          <p>
            - Hotline:{" "}
            <a href="tel:0868889103" style={{ color: "#ffffff" }}>
              086.888.9103
            </a>
          </p>
        </div>
      </div>
    </Modal>
  )
}

export default PurchaseModal

import { nanoid } from 'nanoid'
import { reduce } from 'lodash'
export interface Product {
    name: string,
    price: number,
    brand: "Curnon",
    quantity: number,
    coupon?: string
}
export const sendPurchaseTrigger = (items: Product[]) => {
    const gtag = (window as any).gtag || undefined;
    if (typeof gtag === 'function') {
        const totalMoney = reduce(items, (prev, curr) => {
            return prev + curr.price
        }, 0)
        gtag('event', 'purchase', {
            "transaction_id": nanoid(),
            'affiliation': 'Jackson Landing Page',
            "value": totalMoney,
            "currency": "VND",
            "tax": 0,
            "shipping": 0,
            "items": items
        });
    }

}
export const sendCheckoutTrigger = (items: Product[]) => {
    const gtag = (window as any).gtag || undefined;
    if (typeof gtag === 'function') {
        gtag('event', 'begin_checkout', {
            "items": items,
            "coupon": ""
        });
    }
}
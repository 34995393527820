import React from "react"
import classes from "./spinner.module.scss"
export const Spinner = () => {
  return (
    <div
      className={classes.loader}
      style={{
        height: "24px",
        width: "24px",
      }}
    ></div>
  )
}

import React, { useEffect, useMemo, useRef } from "react"
import { createPortal } from "react-dom"

interface IModal {
  container?: Element
}

const Modal: React.FC<IModal> = ({ children, container }) => {
  const elementRef = useRef<HTMLDivElement>(
    typeof document == "undefined" ? null : document.createElement("div")
  )

  useEffect(() => {
    const modalRoot = document.getElementById("modal")

    modalRoot.appendChild(elementRef.current)

    return () => {
      modalRoot.removeChild(elementRef.current)
    }
  }, [])

  return elementRef.current ? createPortal(children, elementRef.current) : null
}

export default Modal

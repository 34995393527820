import React, { Fragment } from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import "normalize.css"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "../styles/index.scss"
import Footer from "../components/Footer"
import Conclusion from "../components/Conclusion"
import Feedback from "../components/Feedbacks"
import Gallery from "../components/Gallery"
import Products from "../components/Products"
import { Helmet } from "react-helmet"
import ReactPlayer from "react-player"

const Home = ({ data }) => {
  const sources = [
    data.mobileImage.childImageSharp.fluid,
    {
      ...data.desktopImage.childImageSharp.fluid,
      media: `(min-width: 768px)`,
    },
  ]

  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Jackson Landing Page | Curnon Watch</title>
        <link rel="canonical" href="https://jackson.curnonwatch.com/" />
      </Helmet>
      <div className="banner">
        <div className="logo">
          <Img fixed={data.logo.childImageSharp.fixed} />
        </div>
        <Img fluid={sources} />
        <div className="banner__textWrapper">
          <p className="banner__title">Jackson</p>
          <p className="banner__subTitle">
            {`THE FIRST VIETNAMESE
          DIVEWATCH COLLECTION`}
          </p>
          <p className="banner__description">
            {`Unbreakable Watches for Unbreakable Men - Dòng Đồng hồ Lặn đầu tiên đến từ một thương hiệu Việt.
        Thiết kế mạnh mẽ, chống chịu mọi áp lực.`}
          </p>
        </div>
      </div>
      <div className="video">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: 40,
          }}
        >
          <ReactPlayer
            url={`https://www.youtube.com/watch?v=mTZeZhaR0cY`}
            controls={false}
          ></ReactPlayer>
        </div>
        <div className="video__description">
          <div className="quote">
            <p className="text-left">“ UNBREAKABLE WATCHES</p>
            <p className="text-right">
              for <b>UNBREAKABLE MEN</b> ”
            </p>
          </div>
        </div>
      </div>
      <Products />
      <Gallery />
      <Feedback />
      <Conclusion />
      <Footer />
    </Fragment>
  )
}

export const query = graphql`
  query {
    logo: file(relativePath: { eq: "logo.png" }) {
      childImageSharp {
        fixed(width: 100, height: 60) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    mobileImage: file(relativePath: { eq: "banner-mobile.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    desktopImage: file(relativePath: { eq: "banner-desktop.png" }) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default Home

import React from "react"
import classes from "./conclusion.module.scss"

const Conclusion = () => {
  return (
    <div className={classes.root}>
      <p className={classes.text1}>TRỞ THÀNH UNBREAKABLE MEN</p>
      <p className={classes.text2}>chỉ với 3,099,000vnđ</p>
      <a href="#buy">
        <button className={classes.action}>
          <span>SỞ HỮU JACKSON NGAY</span>
        </button>
      </a>
    </div>
  )
}

export default Conclusion

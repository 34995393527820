import Img from "gatsby-image"
import React, { useRef } from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Navigation, Pagination, Scrollbar } from "swiper"
// Import Swiper styles
import "swiper/swiper.scss"
import classes from "./gallery.module.scss"
import { useGallery } from "./useGallery"

// Install modules
SwiperCore.use([Navigation, Pagination, Scrollbar])

const Gallery = props => {
  // const containerRef = useRef<HTMLDivElement>()
  const { images } = useGallery()

  const pos = useRef({ top: 0, left: 0, x: 0, y: 0 })

  // useEffect(() => {
  //   const ele = containerRef.current

  //   const mouseDownHandler = function (e) {
  //     ele.style.cursor = "grabbing"
  //     ele.style.userSelect = "none"
  //     // ele.style.scrollSnapType = null

  //     pos.current = {
  //       left: ele.scrollLeft,
  //       top: ele.scrollTop,
  //       // Get the current mouse position
  //       x: e.clientX,
  //       y: e.clientY,
  //     }

  //     document.addEventListener("mousemove", mouseMoveHandler)
  //     document.addEventListener("mouseup", mouseUpHandler)
  //   }

  //   const mouseMoveHandler = function (e) {
  //     // How far the mouse has been moved
  //     const dx = e.clientX - pos.current.x
  //     const dy = e.clientY - pos.current.y

  //     // Scroll the element
  //     ele.scrollTop = pos.current.top - dy
  //     ele.scrollLeft = pos.current.left - dx
  //   }

  //   const mouseUpHandler = function () {
  //     ele.style.cursor = "grab"
  //     ele.style.removeProperty("user-select")

  //     document.removeEventListener("mousemove", mouseMoveHandler)
  //     document.removeEventListener("mouseup", mouseUpHandler)
  //     pos.current = { top: 0, left: 0, x: 0, y: 0 }
  //   }

  //   containerRef.current.addEventListener("mousedown", mouseDownHandler)

  //   return () => {
  //     containerRef.current.removeEventListener("mousedown", mouseDownHandler)
  //     pos.current = { top: 0, left: 0, x: 0, y: 0 }
  //   }
  // }, [])

  return (
    <div className={classes.root}>
      <p className={classes.text1}>
        Giống như Jackson với vỏ thép cứng cáp chống chịu áp lực nước, người đàn
        ông kiên cường sẽ không lùi bước trước mọi áp lực thành công.
      </p>
      <p className={classes.text2}>
        Chọn lấy Jackson Dive Watch và sẵn sàng đối mặt áp lực, phá vỡ mọi thách
        thức bởi
      </p>
      <p className={classes.text3}>CHÚNG TA LÀ THẾ HỆ</p>
      <div className={classes.composeText}>
        <p className={classes.name1}>{`UNBREAKABLE`}</p>
        <span className={classes.space}> </span>
        <p className={classes.name2}>MEN</p>
      </div>
      <Swiper
        spaceBetween={50}
        slidesPerView={1}
        loop={true}
        // onSlideChange={() => console.log("slide change")}
        // onSwiper={swiper => console.log(swiper)}
        simulateTouch={true}
        breakpoints={{
          480: {
            slidesPerView: 3,
            spaceBetween: 50,
          },
        }}
      >
        {Object.values(images).map((image, index) => {
          return (
            <SwiperSlide key={index} className={classes.imageWrapper}>
              <Img
                fluid={{
                  ...image.childImageSharp.fluid,
                  aspectRatio: 10 / 16,
                }}
                // fixed={image.childImageSharp.fixed}
                style={{
                  pointerEvents: "none",
                  objectFit: "contain",
                }}
              />
            </SwiperSlide>
          )
        })}
      </Swiper>

      {/* <div
        className={classes.images}
        style={{
          display: "flex",
          overflow: "visible",
        }}
        ref={containerRef}
      >
        <span className={classes.spacer} />
        {Object.values(images).map((image, index) => {
          return (
            <div key={index} className={classes.imageWrapper}>
              <Img
                fluid={{
                  ...image.childImageSharp.fluid,
                  aspectRatio: 10 / 16,
                }}
                style={{
                  width: "100%",
                  pointerEvents: "none",
                  objectFit: "contain",
                }}
              />
            </div>
          )
        })}
        <span className={classes.spacer} />
      </div> */}
    </div>
  )
}

export default Gallery

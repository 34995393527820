import { graphql, useStaticQuery } from "gatsby"
import React, { useCallback, useEffect, useState } from "react"

const query = graphql`
  query {
    lavaPreview: file(relativePath: { eq: "lava/preview.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    lavaThumbnail1: file(relativePath: { eq: "lava/1.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    lavaThumbnail2: file(relativePath: { eq: "lava/2.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    lavaThumbnail3: file(relativePath: { eq: "lava/3.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    lavaImage1: file(relativePath: { eq: "lava/1.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    lavaImageDesktop1: file(relativePath: { eq: "lava/1.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    lavaImage2: file(relativePath: { eq: "lava/2.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    lavaImageDesktop2: file(relativePath: { eq: "lava/2.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    lavaImage3: file(relativePath: { eq: "lava/3.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    lavaImageDesktop3: file(relativePath: { eq: "lava/3.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    lavaBg: file(relativePath: { eq: "lava/background.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    lavaBgDesktop: file(relativePath: { eq: "lava/backgroundDesktop.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    earthquakePreview: file(relativePath: { eq: "earthquake/preview.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    earthquakeThumbnail1: file(relativePath: { eq: "earthquake/1.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    earthquakeThumbnail2: file(relativePath: { eq: "earthquake/2.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    earthquakeThumbnail3: file(relativePath: { eq: "earthquake/3.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    earthquakeImage1: file(relativePath: { eq: "earthquake/1.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    earthquakeImageDesktop1: file(relativePath: { eq: "earthquake/1.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    earthquakeImage2: file(relativePath: { eq: "earthquake/2.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    earthquakeImageDesktop2: file(relativePath: { eq: "earthquake/2.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    earthquakeImage3: file(relativePath: { eq: "earthquake/3.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    earthquakeImageDesktop3: file(relativePath: { eq: "earthquake/3.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    earthquakeBg: file(relativePath: { eq: "earthquake/background.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    earthquakeBgDesktop: file(
      relativePath: { eq: "earthquake/backgroundDesktop.png" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    sandstormPreview: file(relativePath: { eq: "sandstorm/preview.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    sandstormThumbnail1: file(relativePath: { eq: "sandstorm/1.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    sandstormThumbnail2: file(relativePath: { eq: "sandstorm/2.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    sandstormThumbnail3: file(relativePath: { eq: "sandstorm/3.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    sandstormImage1: file(relativePath: { eq: "sandstorm/1.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    sandstormImageDesktop1: file(relativePath: { eq: "sandstorm/1.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    sandstormImage2: file(relativePath: { eq: "sandstorm/2.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    sandstormImageDesktop2: file(relativePath: { eq: "sandstorm/2.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    sandstormImage3: file(relativePath: { eq: "sandstorm/3.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    sandstormImageDesktop3: file(relativePath: { eq: "sandstorm/3.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    sandstormBg: file(relativePath: { eq: "sandstorm/background.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    sandstormBgDesktop: file(
      relativePath: { eq: "sandstorm/backgroundDesktop.png" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    blizzardPreview: file(relativePath: { eq: "blizzard/preview.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    blizzardThumbnail1: file(relativePath: { eq: "blizzard/1.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    blizzardThumbnail2: file(relativePath: { eq: "blizzard/2.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    blizzardThumbnail3: file(relativePath: { eq: "blizzard/3.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    blizzardImage1: file(relativePath: { eq: "blizzard/1.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    blizzardImageDesktop1: file(relativePath: { eq: "blizzard/1.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    blizzardImage2: file(relativePath: { eq: "blizzard/2.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    blizzardImageDesktop2: file(relativePath: { eq: "blizzard/2.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    blizzardImage3: file(relativePath: { eq: "blizzard/3.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    blizzardImageDesktop3: file(relativePath: { eq: "blizzard/3.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    blizzardBg: file(relativePath: { eq: "blizzard/background.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    blizzardBgDesktop: file(
      relativePath: { eq: "blizzard/backgroundDesktop.png" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    thunderPreview: file(relativePath: { eq: "thunder/preview.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    thunderThumbnail1: file(relativePath: { eq: "thunder/1.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    thunderThumbnail2: file(relativePath: { eq: "thunder/2.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    thunderThumbnail3: file(relativePath: { eq: "thunder/3.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    thunderImage1: file(relativePath: { eq: "thunder/1.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    thunderImageDesktop1: file(relativePath: { eq: "thunder/1.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    thunderImage2: file(relativePath: { eq: "thunder/2.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    thunderImageDesktop2: file(relativePath: { eq: "thunder/2.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    thunderImage3: file(relativePath: { eq: "thunder/3.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    thunderImageDesktop3: file(relativePath: { eq: "thunder/3.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    thunderBg: file(relativePath: { eq: "thunder/background.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    thunderBgDesktop: file(
      relativePath: { eq: "thunder/backgroundDesktop.png" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const useProductFullDetail = ({ products, mobileSliderRef }) => {
  const images = useStaticQuery(query)

  const [selectedProductId, setSelectedProductId] = useState<number | string>(
    products[0].sku
  )

  const [currentImageIndex, setCurrentImageIndex] = useState<number>(0)

  // when change product we set the current index to 0
  useEffect(() => {
    //for desktop
    setCurrentImageIndex(0)
    // for mobile
    mobileSliderRef.current?.slickGoTo(0, true)
  }, [selectedProductId])

  const onProductThumbnailClick = index => {
    setCurrentImageIndex(index)
  }

  const handleProductChange = useCallback(sku => {
    setSelectedProductId(sku)
  }, [])

  const selectedProduct = products.find(
    product => product.sku === selectedProductId
  )

  return {
    handleProductChange,
    selectedProduct,
    images,
    currentImageIndex,
    onProductThumbnailClick,
  }
}

export default useProductFullDetail

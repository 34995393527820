import { graphql, useStaticQuery } from "gatsby"
import React, { useCallback, useEffect, useState } from "react"

const query = graphql`
  query {
    image1: file(relativePath: { eq: "gallery/1.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
        fixed(height: 600, width: 400) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    image2: file(relativePath: { eq: "gallery/2.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
        fixed(height: 600, width: 400) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    image3: file(relativePath: { eq: "gallery/3.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
        fixed(height: 600, width: 400) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    image4: file(relativePath: { eq: "gallery/4.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
        fixed(height: 600, width: 400) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    image5: file(relativePath: { eq: "gallery/5.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
        fixed(height: 600, width: 400) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    image6: file(relativePath: { eq: "gallery/6.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
        fixed(height: 600, width: 400) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    image7: file(relativePath: { eq: "gallery/7.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
        fixed(height: 600, width: 400) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

export const useGallery = () => {
  const images = useStaticQuery(query)

  return { images }
}

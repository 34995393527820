import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import classes from "./footer.module.scss"
import Img from "gatsby-image"

const query = graphql`
  query {
    logo: file(relativePath: { eq: "logo-dark.png" }) {
      childImageSharp {
        fixed(width: 100, height: 60) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

const Footer = () => {
  const data = useStaticQuery(query)

  // const src = data.logo.childImageSharp.fixed

  return (
    <div className={classes.root}>
      <p className={classes.copyright}>© 2020 Curnon Watch. All Rights Reserved.</p>
      <Img fixed={data.logo.childImageSharp.fixed} className="logo" />
    </div>
  )
}

export default Footer

import React, { useMemo, useRef, useState } from "react"
import classes from "./products.module.scss"
import Img from "gatsby-image"
import useProductFullDetail from "./useProductFullDetail"
import PurchaseModal from "../PurchaseModal"
import Slider from "react-slick"
import classNames from "classnames"
import lavaWatchImage from "../../images/lava/thumbnail.png"
import lavaBgImage from "../../images/lava/thumbnailBg.png"
import earthquakeWatchImage from "../../images/earthquake/thumbnail.png"
import earthquakeBgImage from "../../images/earthquake/thumbnailBg.png"
import sandstormWatchImage from "../../images/sandstorm/thumbnail.png"
import sandstormBgImage from "../../images/sandstorm/thumbnailBg.png"
import blizzardWatchImage from "../../images/blizzard/thumbnail.png"
import blizzardBgImage from "../../images/blizzard/thumbnailBg.png"
import thunderWatchImage from "../../images/thunder/thumbnail.png"
import thunderBgImage from "../../images/thunder/thumbnailBg.png"
const data = [
  {
    sku: 1,
    name: "lava",
    description:
      "Với texture đặc biệt trên phần mặt đồng hồ kết hợp cùng Rosegold và Matte Black giúp “Bão sa Mạc” trở thành điểm nhấn khác biệt và mạnh mẽ nhất.",
    size: "40mm",
    glass: "sapphire",
    water_resist: "10atm",
    material: "THÉP KHÔNG GỈ",
    price: `3,099,000vnđ`,
    thumbnailWatchImage: lavaWatchImage,
    thumbnailBgImage: lavaBgImage,
  },
  {
    sku: 2,
    name: "earthquake",
    description:
      "Với texture đặc biệt trên phần mặt đồng hồ kết hợp cùng Rosegold và Matte Black giúp “Bão sa Mạc” trở thành điểm nhấn khác biệt và mạnh mẽ nhất.",
    size: "40mm",
    glass: "sapphire",
    water_resist: "10atm",
    material: "THÉP KHÔNG GỈ",
    price: `3,099,000vnđ`,
    thumbnailWatchImage: earthquakeWatchImage,
    thumbnailBgImage: earthquakeBgImage,
  },
  {
    sku: 3,
    name: "sandstorm",
    description:
      "Với texture đặc biệt trên phần mặt đồng hồ kết hợp cùng Rosegold và Matte Black giúp “Bão sa Mạc” trở thành điểm nhấn khác biệt và mạnh mẽ nhất.",
    size: "40mm",
    glass: "sapphire",
    water_resist: "10atm",
    material: "THÉP KHÔNG GỈ",
    price: `3,099,000vnđ`,
    thumbnailWatchImage: sandstormWatchImage,
    thumbnailBgImage: sandstormBgImage,
  },
  {
    sku: 4,
    name: "blizzard",
    description:
      "Với texture đặc biệt trên phần mặt đồng hồ kết hợp cùng Rosegold và Matte Black giúp “Bão sa Mạc” trở thành điểm nhấn khác biệt và mạnh mẽ nhất.",
    size: "40mm",
    glass: "sapphire",
    water_resist: "10atm",
    material: "THÉP KHÔNG GỈ",
    price: `3,099,000vnđ`,
    thumbnailWatchImage: blizzardWatchImage,
    thumbnailBgImage: blizzardBgImage,
  },
  {
    sku: 5,
    name: "thunder",
    description:
      "Với texture đặc biệt trên phần mặt đồng hồ kết hợp cùng Rosegold và Matte Black giúp “Bão sa Mạc” trở thành điểm nhấn khác biệt và mạnh mẽ nhất.",
    size: "40mm",
    glass: "sapphire",
    water_resist: "10atm",
    material: "THÉP KHÔNG GỈ",
    price: `3,099,000vnđ`,
    thumbnailWatchImage: thunderWatchImage,
    thumbnailBgImage: thunderBgImage,
  },
]

const settings = {
  className: "productImgSlider",
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  adaptiveHeight: true,
  // initialSlide: 0,
  // responsive: [
  //   {
  //     breakpoint: 1281,
  //     settings: {
  //       slidesToShow: 2,
  //       slidesToScroll: 2,
  //       variableWidth: false,
  //     },
  //   },
  //   {
  //     breakpoint: 769,
  //     settings: {
  //       slidesToShow: 1,
  //       slidesToScroll: 1,
  //       variableWidth: false,
  //     },
  //   },
  //   // {
  //   //   breakpoint: 480,
  //   //   settings: {
  //   //     slidesToShow: 1,
  //   //     slidesToScroll: 1,
  //   //     variableWidth: false,
  //   //   },
  //   // },
  // ],
}

const Products = () => {
  const mobileSliderRef = useRef<Slider>(null)

  const {
    handleProductChange,
    selectedProduct,
    images,
    currentImageIndex,
    onProductThumbnailClick,
  } = useProductFullDetail({
    products: data,
    mobileSliderRef,
  })
  const [visible, setModalVisible] = useState<boolean>(false)

  const sources = useMemo(() => {
    const selectedProductName = selectedProduct.name

    const preview =
      images[`${selectedProductName}Preview`].childImageSharp.fluid

    const thumbnail = [
      images[`${selectedProductName}Preview`].childImageSharp.fluid,
      {
        ...images[`${selectedProductName}Preview`].childImageSharp.fluid,
        media: `(min-width: 1024px)`,
      },
    ]

    const productImages = [
      images[`${selectedProductName}Preview`].childImageSharp.fluid,
      images[`${selectedProductName}Image1`].childImageSharp.fluid,
      images[`${selectedProductName}Image2`].childImageSharp.fluid,
      images[`${selectedProductName}Image3`].childImageSharp.fluid,
    ]

    const productDesktopImages = [
      images[`${selectedProductName}Preview`].childImageSharp.fluid,
      images[`${selectedProductName}ImageDesktop1`].childImageSharp.fluid,
      images[`${selectedProductName}ImageDesktop2`].childImageSharp.fluid,
      images[`${selectedProductName}ImageDesktop3`].childImageSharp.fluid,
    ]
    const productBg = [
      images[`${selectedProductName}Bg`].childImageSharp.fluid,
      {
        ...images[`${selectedProductName}BgDesktop`].childImageSharp.fluid,
        media: `(min-width: 1024px)`,
      },
    ]

    return {
      preview,
      thumbnail,
      productImages,
      productDesktopImages,
      productBg,
    }
  }, [selectedProduct, images])

  return (
    <div className={classes.root} id="buy">
      <div className={classes.inner}>
        <div className={classes.thumbnails}>
          {data.map(product => {
            const isSelected = product.sku === selectedProduct.sku

            return (
              <button
                key={product.sku}
                className={classes.thumbnail}
                onClick={() => handleProductChange(product.sku)}
              >
                <div style={{ position: "relative" }}>
                  <div className={classes.imgWrapper}>
                    <img
                      className={classNames(classes.bgImage, {
                        [classes.bgImageIsSelected]: isSelected,
                      })}
                      alt="bg"
                      src={product.thumbnailBgImage}
                    />

                    <img
                      className={classNames(classes.watchImage, {
                        [classes.watchImageIsSelected]: isSelected,
                      })}
                      alt="product"
                      src={product.thumbnailWatchImage}
                    />
                  </div>
                  <div
                    className={classNames(classes.borderFake, {
                      [classes.borderFakeSelected]: isSelected,
                    })}
                  />
                </div>
              </button>
            )
          })}
        </div>
        <div className={classes.productImageWrapper}>
          <div style={{ height: "100%" }}>
            <div className={classes.mobile}>
              <Slider {...settings} ref={mobileSliderRef}>
                <Img
                  fluid={sources.productImages[0]}
                  className={classes.productImage}
                />
                <Img
                  fluid={sources.productImages[1]}
                  className={classes.productImage}
                />
                <Img
                  fluid={sources.productImages[2]}
                  className={classes.productImage}
                />
                <Img
                  fluid={sources.productImages[3]}
                  className={classes.productImage}
                />
              </Slider>
            </div>
            <div className={classes.desktop}>
              <div className={classes.productImageThumbnails}>
                <div
                  className={classes.thumbnailSkel}
                  onClick={() => onProductThumbnailClick(0)}
                >
                  <Img
                    fluid={
                      images[`${selectedProduct.name}Preview`].childImageSharp
                        .fluid
                    }
                    style={{
                      width: "100%",
                      objectFit: "contain",
                    }}
                  />
                </div>
                <div
                  className={classes.thumbnailSkel}
                  onClick={() => onProductThumbnailClick(1)}
                >
                  <Img
                    fluid={
                      images[`${selectedProduct.name}Thumbnail1`]
                        .childImageSharp.fluid
                    }
                    style={{
                      width: "100%",
                      objectFit: "contain",
                    }}
                  />
                </div>
                <div
                  className={classes.thumbnailSkel}
                  onClick={() => onProductThumbnailClick(2)}
                >
                  <Img
                    fluid={
                      images[`${selectedProduct.name}Thumbnail2`]
                        .childImageSharp.fluid
                    }
                    style={{
                      width: "100%",
                      objectFit: "contain",
                    }}
                  />
                </div>
                <div
                  className={classes.thumbnailSkel}
                  onClick={() => onProductThumbnailClick(3)}
                >
                  <Img
                    fluid={
                      images[`${selectedProduct.name}Thumbnail3`]
                        .childImageSharp.fluid
                    }
                    style={{
                      width: "100%",
                      objectFit: "contain",
                    }}
                  />
                </div>
              </div>
              <Img
                className={classes.productImage}
                fluid={sources.productDesktopImages[currentImageIndex]}
                style={{ height: "500px", width: "500px" }}
                imgStyle={{ objectFit: "cover" }}
              />
            </div>
          </div>
          <div className={classes.productBgImg}>
            {/* <Img fluid={sources.productBg} style={{ height: "100%" }} /> */}
          </div>
        </div>
        <div className={classes.productDetails}>
          <div className={classes.productInfo}>
            <p className={classes.productName}>{selectedProduct.name}</p>
            <div className={classes.specs}>
              <div className={classes.spec}>
                <p className={classes.label}>size</p>
                <p className={classes.value}>{selectedProduct.size}</p>
              </div>

              <div className={classes.spec}>
                <p className={classes.label}>mặt kính</p>
                <p className={classes.value}>{selectedProduct.glass}</p>
              </div>

              <div className={classes.spec}>
                <p className={classes.label}>chống nước</p>
                <p className={classes.value}>{selectedProduct.water_resist}</p>
              </div>

              <div className={classes.spec}>
                <p className={classes.label}>chất liệu</p>
                <p className={classes.value}>{selectedProduct.material}</p>
              </div>
            </div>
            <button
              className={classes.buyButton}
              onClick={() => {
                window.location.href =
                  "https://curnonwatch.com/dong-ho/dong-ho-nam/dong-ho-nam-jackson"
                // setModalVisible(true)
              }}
            >
              <span>{`MUA ${selectedProduct.name} NGAY`}</span>
              <span>{selectedProduct.price}</span>
            </button>
            <p className={classes.description}>{selectedProduct.description}</p>
          </div>
        </div>
      </div>
      <div className={classes.services}>
        <div className={classes.service}>
          <img src={require("../../images/truck.png")} />
          <p>{"MIỄN PHÍ VẬN CHUYỂN TOÀN QUỐC"}</p>
        </div>
        <div className={classes.service}>
          <img src={require("../../images/shield.png")} />
          <p>{"1 ĐỔI 1 TRONG VÒNG 3 NGÀY,\nBẢO HÀNH 10 NĂM"}</p>
        </div>
      </div>
      <PurchaseModal
        visible={visible}
        product={selectedProduct}
        images={sources}
        onClose={() => setModalVisible(false)}
      />
    </div>
  )
}

export default Products

import React, { useState } from "react"
import classes from "./feedbacks.module.scss"
import Slider from "react-slick"
import Rating from "react-star-ratings"
import Img from "gatsby-image"
import Modal from "../Modal"
import { Lightbox } from "../Image/lightbox"
import { graphql, useStaticQuery } from "gatsby"

var settings = {
  className: "feedbackSlider",
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  initialSlide: 0,
  variableWidth: true,
  responsive: [
    {
      breakpoint: 1600,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        variableWidth: false,
      },
    },
    // {
    //   breakpoint: 1441,
    //   settings: {
    //     slidesToShow: 3,
    //     slidesToScroll: 3,
    //     variableWidth: false,
    //   },
    // },
    {
      breakpoint: 1281,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        variableWidth: false,
      },
    },
    {
      breakpoint: 769,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: false,
      },
    },
    // {
    //   breakpoint: 480,
    //   settings: {
    //     slidesToShow: 1,
    //     slidesToScroll: 1,
    //     variableWidth: false,
    //   },
    // },
  ],
}

const query = graphql`
  query {
    image1: file(relativePath: { eq: "feedbacks/1.1.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
        fixed(width: 40, height: 40) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    image2: file(relativePath: { eq: "feedbacks/2.1.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
        fixed(width: 40, height: 40) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    image3: file(relativePath: { eq: "feedbacks/2.2.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
        fixed(width: 40, height: 40) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

const Feedback = ({ id, name, body, rating, pics, openImage }) => {
  return (
    <div className={classes.slide}>
      <div className={classes.ratingAndName}>
        <Rating
          rating={rating}
          starRatedColor="#F6D72F"
          // changeRating={this.changeRating}
          numberOfStars={5}
          name="rating"
          starDimension="18px"
          starSpacing="1px"
          svgIconViewBox="0 0 18 18"
          svgIconPath="M11.6719 5.73438L8.92969 0.144531L6.15234 5.73438L0 6.64844L4.46484 10.9727L3.41016 17.125L8.92969 14.2422L14.4141 17.125L13.3945 10.9727L17.8242 6.64844L11.6719 5.73438Z"
        />
        <p className={classes.name}> - {name}</p>
      </div>
      <p className={classes.title}>{name}</p>
      <p className={classes.body}>{body}</p>
      <div className={classes.feedbackImages}>
        {pics.map((pic, index) => (
          <div
            key={index}
            className={classes.imageWrapper}
            onClick={() => {
              openImage(pic.childImageSharp.fluid)
            }}
          >
            <Img
              fixed={pic.childImageSharp.fixed}
              style={
                {
                  // width: "100%",
                  // objectFit: "contain",
                }
              }
            />
          </div>
        ))}
      </div>
    </div>
  )
}

const Feedbacks = () => {
  const pics = useStaticQuery(query)
  const [openModal, setOpenModal] = useState(false)
  const [fluid, setFluid] = useState(false)
  const openImage = fluid => {
    setOpenModal(true)
    setFluid(fluid)
  }
  const feedbacks = [
    {
      id: 1,
      rating: 5,
      name: "Le Duc",
      title: "Xứng đáng!!!",
      body: `Thời điểm này năm ngoái tò mò và mua Weimar!
      Sau một năm tự thưởng cho mình Jackson Sandstorm. Họa tiết trên mặt rất đặc biệt, đeo lên tay cực kì nổi bật.`,
      pics: [pics.image1],
    },
    {
      id: 2,
      rating: 5,
      name: "Huy Dinh",
      title: "Ấn tượng với phối màu đen",
      body: `Mình phân vân khá nhiều nhưng lựa chọn Jackson Lava và đã không làm mình thất vọng. Đeo lên phối đồ cảm giác rất mạnh mẽ và sport. Mình cũng đã thử đeo đi bơi rất ổn không có vấn đề gì. Mong Curnon tiếp tục phát huy`,
      pics: [pics.image2, pics.image3],
    },
  ]

  return (
    <div className={classes.root}>
      <p className={classes.text}>CUSTOMER FEEDBACKS</p>

      <div className={classes.sliderWrapper}>
        <Slider {...settings}>
          {feedbacks.map(feedback => {
            return (
              <Feedback key={feedback.id} {...feedback} openImage={openImage} />
            )
          })}
        </Slider>
      </div>
      {openModal && (
        <Modal>
          <Lightbox
            fluid={fluid}
            close={() => {
              setOpenModal(false)
            }}
          ></Lightbox>
        </Modal>
      )}
    </div>
  )
}

export default Feedbacks
